import React, { useState, FC } from 'react';
import cn from 'classnames';
import Image from 'components/atoms/Image/Image';

import './Testimonials.scss';

type TestimonialProps = {
  text: string;
  name: string;
  position: string;
  index: number;
  className?: string;
};

const Testimonials: FC = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const [nextCard, setNextCard] = useState(1);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isReverse, setIsReverse] = useState(false);

  const handleNextCard = (increment: boolean) => {
    setIsReverse(!increment);

    let upcomingCard = increment ? currentCard + 1 : currentCard - 1;
    if (upcomingCard < 0) {
      upcomingCard = testimonials.length - 1;
    }

    if (upcomingCard > testimonials.length - 1) {
      upcomingCard = 0;
    }

    setNextCard(upcomingCard);

    let followingCard = increment ? upcomingCard + 1 : upcomingCard - 1;
    if (followingCard < 0) {
      followingCard = testimonials.length - 1;
    }

    if (followingCard > testimonials.length - 1) {
      followingCard = 0;
    }

    setIsAnimated(true);

    setTimeout(() => {
      setIsReverse(false);
      setIsAnimated(false);
      setNextCard(() => followingCard);
      setCurrentCard(() => upcomingCard);
    }, 400);
  };

  const testimonials = [
    {
      id: '1',
      name: 'Uffe Refsgaard',
      position: 'Cloud Developer @ Targit A/S',
      text: `We got a lot 'out-of-the-box' right away.
      Fully setting up enterprise-ready AKS Kubernetes clusters in Azure is easy with Fractal.
      That got us very far, very fast. But also adding other components like storage accounts, key vaults, and app services is very easy and fast to do.
      What I like the most about Fractal Cloud is the intuitive, fluent API available when declaring cloud components in Java.
      With Intellisense, finding the right property and selecting the correct value is really easy.
      I would recommend using Factal. The team behind it is very capable and always ready to help. Talk to Yanchware about your needs.`,
      imageName: 'targit',
      imageType: 'svg',
    },
    {
      id: '2',
      name: 'Steen Møller',
      position: 'Enterprise Architect @ Stark',
      text: `As the front end took shape, there was a need for rights management in an event-based architecture. This is where Ocelot came into play and has easily secured
      context-driven rights in the microservice architecture. YanchWare has been a key player in the momentum in forming a new architecture and baseline for the new STARK ERP`,
      imageName: 'stark',
      imageType: 'svg',
    },
    {
      id: '3',
      name: 'Pernille Geneser',
      position: 'CIO @ Stark',
      text: `YanchWare has been with us all the way and in a short time has gained a connection between the old and the
      new world in cooperation with other partners.
      STARK have used that success to build a new frontend for the old ERP system based on Events provided by YanchWare’s effort`,
      imageName: 'stark',
      imageType: 'svg',
    },
  ];

  const Testimonial: FC<TestimonialProps> = ({ text, name, position, index, className = '' }) => (
    <div
      className={cn(
        'flex w-[300px] top-[30px] items-center h-[540px] pb-[40px] tablet-up:w-[600px] tablet-up:h-[320px] shadow-testimonial rounded-[10px] bg-white',
        'absolute',
        { 'animation-top': index === 0 && isAnimated && !isReverse },
        { 'animation-top--reverse': index === 0 && isAnimated && isReverse },
        { 'animation-top-appears': index === 0 && !isAnimated && isReverse },
        { 'animation-central': index === 1 && isAnimated && !isReverse },
        { 'top-[0px] z-30 shadow-testimonials': index === 0 },
        {
          'scale-x-[0.9] scale-y-[0.95] top-[30px] tablet-up:top-[20px] z-20 shadow-testimonials':
            index === 1,
        },
        { 'scale-x-[0.8] scale-y-[0.9] top-[60px] tablet-up:top-[40px] z-10': index === 2 },
        { 'animation-bottom--reverse shadow-testimonials': index === 2 && isAnimated && isReverse },
        { 'animation-bottom shadow-testimonials': index === 2 && isAnimated && !isReverse },
        { 'shadow-testimonials': index === 2 && !isAnimated },
        { 'scale-x-[0.8] scale-y-[0.9] top-[60px] tablet-up:top-[40px] z-0': index > 2 },
        { 'shadow-testimonials': index > 2 && isAnimated },
        { invisible: index > 3 },
        `${className}`
      )}
    >
      <div className="absolute flex w-full justify-center">
        <Image name="quote" className="w-[160px] h-[160px] mt-s" />
      </div>
      <div className="h-fit tablet-up:h-[280px] tablet-up:pt-s">
        <div
          className={`relative flex h-[360px] tablet-up:h-[200px] scrollbar-hide overflow-y-scroll tablet-up:w-[500px]  tablet-up:min-h-[170px]
            mt-0 tablet-up:my-[16px] mx-[32px] tablet-up:mx-[56px] my-auto
            text-base-300 font-[500] leading-[175%] text-left items-center
          `}
        >
          <div className="my-auto">{text}</div>
        </div>
        <div className="mt-m tablet-up:mt-[0px] px-[26px] tablet-up:px-[56px] text-right font-bold leading-[175%]">
          {name}
        </div>
        {position !== '' && (
          <div className=" px-[26px] tablet-up:px-[56px] text-right font-bold leading-[175%]">
            {position}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="relative flex h-[920px] tablet-up:h-[420px] tablet-up:my-xl justify-center items-center">
        {testimonials.map((_testimonial, i) => {
          return (
            <Testimonial
              key={`testimonial-element--${i}`}
              text={i === 0 ? testimonials[currentCard]?.text : testimonials[nextCard]?.text}
              name={i === 0 ? testimonials[currentCard]?.name : testimonials[nextCard]?.name}
              position={
                i === 0 ? testimonials[currentCard]?.position : testimonials[nextCard]?.position
              }
              index={i}
              className={currentCard === 0 ? 'active' : ''}
            />
          );
        })}
        <div
          className="relative bottom-0 flex max-w-[1280px] w-full mx-auto top-[50px]
          tablet-up:pt-[240px] tablet:pr-[120px] desktop:pr-[340px] justify-end z-40 tablet-up:z-0"
        >
          <button onClick={() => handleNextCard(false)} className="w-fit my-auto mr-s">
            <Image name="arrow-icon-thin" className="block fill-black w-m h-m rotate-180" />
          </button>
          <button onClick={() => handleNextCard(true)} className="w-fit my-auto">
            <Image name="arrow-icon-thin" className="block fill-black w-[56px] h-[56px]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
