import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';
import Testimonials from 'components/Carousel/Testimonials/Testimonials';
import Layout from 'components/Layout';
import ProductSlider from 'components/ProductSlider/ProductSlider';
import Seo from 'components/SEO';
import { Link } from 'gatsby';
import React from 'react';
import 'scss/pages/homepage.scss';
import { fractalHighlights, fractalImages } from '../data/fractalProductHighlights';

const Homepage = () => {
  return (
    <Layout className="homepage">
      <Seo title="Home" />
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Your platform, your control.</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                Streamlining infrastructure Management with Platform Engineering solutions focused
                on enabling outstanding development
              </Trans>
            </p>
            <Link
              to="/contact-us"
              className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
            >
              <span className="text-transparent bg-clip-text bg-hero-text">
                <Trans>Get in touch</Trans>
              </span>
              <Image name="arrow-icon" className="fill-icon mr-s" />
            </Link>
          </div>
          <Image name="home-page/home-page-hero-2" className="hero__image" />
        </div>
      </section>

      <section className="accomplish-more">
        <h2 className="accomplish-more__title flex justify-center flex-wrap md:flex-nowrap text-transparent bg-clip-text bg-hero-text">
          <div className="mr-xs text-center font-[600] text-[34px] tracking-0.5px">
            <Trans>Accomplish more </Trans>
          </div>
          <div className="mr-xs text-center font-[400] text-[34px] tracking-0.5px">
            <Trans> on your own terms</Trans>
          </div>
        </h2>
        <p className="desktop:w-[1050px] mx-auto mb-[120px] text-[24px] text-base-300 text-center">
          <Trans>
            At YanchWare we are Transforming the Technology Landscape by Empowering Developers,
            Companies, and Communities to Drive Change.
          </Trans>
        </p>
        <div className="">
          <div className="flex flex-wrap desktop:flex-nowrap w-full">
            <div className="basis-[100%] desktop:basis-1/3 h-fit mx-xs my-xs tablet:mx-xl tablet:pr-l desktop:m-s p-s rounded-[5px] shadow-cardNew">
              <Image className="block w-[40px] h-[40px] mb-s" name="team" />
              <div className="pb-s text-[20px] tablet:text-[18px] font-[500] leading-[150%]">
                Your development team
              </div>
              <div className="mb-[44px] tablet:text-[14px] leading-[175%]">
                Can take over the full responsibility for the specific infrastructure their
                applications need to run on; only and exclusively that.
              </div>
              <div className="mt-s font-[600] tablet:text-[14px] leading-[175%]">
                Governance without extra complexity or specialized resources.
              </div>
            </div>
            <div className="basis-[100%] desktop:basis-1/3 h-fit mx-xs my-xs tablet:mx-xl tablet:pr-l desktop:m-s p-s rounded-[5px] shadow-cardNew">
              <Image className="block w-[40px] h-[40px] mb-s" name="company" />
              <div className="pb-s text-[20px] tablet:text-[18px] font-[500] leading-[150%]">
                Your company
              </div>
              <div className="mb-[44px] tablet:text-[14px] tablet:mt-[30px] leading-[175%]">
                Can take the decisional power back and choose any vendor, at any point in time and
                at any degree necessary.
              </div>
              <div className="mt-s font-[600] tablet:text-[14px] leading-[175%]">
                A granular control system without costly home-made platforms.
              </div>
            </div>
            <div className="basis-[100%] desktop:basis-1/3 h-fit mx-xs my-xs tablet:mx-xl tablet:pr-l desktop:m-s p-s rounded-[5px] shadow-cardNew">
              <Image className="block w-[40px] h-[40px] mb-s" name="community" />
              <div className="pb-s text-[20px] tablet:text-[18px] font-[500] leading-[150%]">
                Your communities
              </div>
              <div className="tablet:mt-[30px] tablet:text-[14px] leading-[175%]">
                Rather than calcifying around pre-chosen technologies, they can focus on solving the
                problems and challenges that face them.
              </div>
              <div className="mt-s font-[600] tablet:text-[14px] leading-[175%]">
                Prioritize business value and time-to-market without reinventing the wheel.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="enable-your-teams">
        <div className="content-blocks content-blocks--reverse">
          <div className="content-block">
            <div className="content-block__info w-[90%] tablet-up:w-auto">
              <div className="mb-l desktop:mt-[40px] text-center tablet-up:text-left text-[28px] desktop:text-[34px] font-[600]">
                Enable your teams
              </div>
              <div className="leading-[175%] text-center tablet-up:text-left">
                Our products and services are designed to let your company focus on your customers
                and business opportunities, letting your teams decide on the degree of
                infrastructure complexity they want to take on.
              </div>
              <div className="mt-[40px] text-center tablet-up:text-left text-[20px] tablet:text-[22px] font-[500] leading-[150%]">
                We help you ensure you can operate completely independently, now and in the future.
              </div>
            </div>
            <Image name="enable-your-teams" className="content-block__image" />
          </div>
        </div>
      </section>

      <section className="our-products">
        <h2 className="text-center text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          <Trans>Our products</Trans>
        </h2>
        <p className="desktop:w-[1050px] mx-auto mb-[120px] text-[24px] text-base-300 text-center">
          <Trans>
            Our platforms and portals will radically reduce the time and resources you spend on
            infrastructure management, audit, security assessments, and coordination.
          </Trans>
        </p>
        <div className="content-blocks">
          <ProductSlider
            hightlights={fractalHighlights}
            imageNames={fractalImages}
            timeout={5000}
            title="Fractal Cloud"
            subTitle="Your Internal Developer Platform. Ready Today."
          />
          <div className="content-block">
            <Image className="content-block__image" name="home-page/ocelot-product" />
            <div className="content-block__info">
              <h3 className="flex">
                <Image name="ocelot-logo-grey" className="w-[56px] h-[56px] mt-xs" />
                <div className="ml-xs">
                  <div className="text-[24px] font-semibold text-base-900 font-sans">
                    <Trans>Ocelot</Trans>
                  </div>
                  <div className="text-[20px] text-base-800 font-[500]">
                    <Trans>Enhanced security for your Service Mesh</Trans>
                  </div>
                </div>
              </h3>
              <ul className="product__highlight mb-xl">
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>Define security policies on business operations</Trans>
                </li>
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>Assign segregated identities to each service</Trans>
                </li>
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>Granular control of east-west traffic for both services and users</Trans>
                </li>
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>Ensure security on any application protocol (not only HTTP)</Trans>
                </li>
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>Enterprise ready with RBAC and Multi-tenancy</Trans>
                </li>
                <li className="mb-s text-base-600 leading-[150%]">
                  <Image
                    name="check-mark-grey-round"
                    className="inline-block min-w-[16px] h-s top-[3px] relative mr-[5px]"
                  />
                  <Trans>
                    Complete visibility and governance while delegating policy ownership to the
                    Development Teams
                  </Trans>
                </li>
              </ul>
              <Link
                to="/ocelot"
                className="m-auto tablet-up:ml-[0px] bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
              >
                <span className="text-white">
                  <Trans>Read More</Trans>
                </span>
                <Image name="arrow-icon-white" className="fill-white mr-s" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="our-services">
        <h2 className="text-center text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          <Trans>Our services</Trans>
        </h2>
        <p className="desktop:w-[700px] mx-auto mb-[120px] text-center text-base-300 text-[24px]">
          <Trans>
            We offer an exceptional range of services, carefully designed to ensure your teams have
            the edge
          </Trans>
        </p>
        <div className="content-blocks content-blocks--reverse">
          <div className="content-block">
            <Image
              className="content-block__image w-[300px] desktop:w-[350px] desktop:ml-l"
              name="cloud-reliability"
            />
            <div className="content-block__info">
              <h3 className="service__title text-center tablet-up:text-left">
                <Trans>Cloud Reliability Center</Trans>
              </h3>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  Focus on what you do best while our specialized teams take care of your
                  infrastructure 24/7.
                </Trans>
              </p>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  Through our tools, experience and automated Operations Center, we are able to
                  support a myriad of installations across data centers distributed around the
                  globe.
                </Trans>
              </p>
              <Link
                to="/services#cloud-reliability"
                className="m-auto tablet-up:ml-[0px] bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
              >
                <span className="text-white">
                  <Trans>Read More</Trans>
                </span>
                <Image name="arrow-icon-white" className="fill-white mr-s" />
              </Link>
            </div>
          </div>
          <div className="content-block">
            <Image className="content-block__image" name="training" />
            <div className="content-block__info">
              <h3 className="service__title text-center tablet-up:text-left">
                <Trans>Training</Trans>
              </h3>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  We hold tailored hands-on training for teams to become more efficient in their
                  work on multiple cloud vendors (Azure, GCP, AWS) and models (IaaS, CaaS, PaaS,
                  FaaS, etc.)
                </Trans>
              </p>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  Together with your team we analyze their current situation regarding cloud-native,
                  big-data, and related technologies embarking on a specific learning path aligned
                  with your business ambitions.
                </Trans>
              </p>
              <Link
                to="/services#training"
                className="m-auto tablet-up:ml-[0px] bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
              >
                <span className="text-white">
                  <Trans>Read More</Trans>
                </span>
                <Image name="arrow-icon-white" className="fill-white mr-s" />
              </Link>
            </div>
          </div>
          <div className="content-block">
            <Image className="content-block__image" name="consultancy" />
            <div className="content-block__info">
              <h3 className="service__title text-center tablet-up:text-left">
                <Trans>Consultancy</Trans>
              </h3>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  From Strategy and Management Consultancy focused on Cloud Adoption and Agile
                  Methodologies, to pragmatic Development of Cloud Native Applications, we have
                  professionals ready to join your teams and help you raise the bar.
                </Trans>
              </p>
              <p className="service__text text-center tablet-up:text-left">
                <Trans>
                  Furthermore, we are specialized in turning legacy systems into competitive
                  advantage, allowing your company to capitalize on the existing system and move
                  forward at the pace of start-ups.
                </Trans>
              </p>
              <Link
                to="/services#consulting"
                className="m-auto tablet-up:ml-[0px] bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between"
              >
                <span className="text-white">
                  <Trans>Read More</Trans>
                </span>
                <Image name="arrow-icon-white" className="fill-white mr-s" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="flex max-w-[1280px] m-auto items-stretch">
        <div className="worked-with">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            <Trans>Companies that worked with us</Trans>
          </h2>
          <div className="company-icons">
            <Image name="stark" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="targit" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="ebay" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="schibsted" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="bestseller" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="unicef" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="aarhus-university" className="w-[140px] tablet-up:w-[200px]" />
            <Image name="danske-bank" className="w-[140px] tablet-up:w-[200px]" />
          </div>
        </div>
      </section>

      <section className="testimonials-wrapper">
        <h2 className="text-center text-[34px] font-[500] text-icon pt-l phone:mx-m">
          What our clients say
        </h2>
        <div className="mb-[64px] text-center text-[24px] font-[500] text-base-300 leading-[175%]">
          See what our satisfied customers have to say about us.
        </div>
        <Testimonials />
      </section>
    </Layout>
  );
};

export default Homepage;
